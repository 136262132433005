import { primary } from '../util/theme';
import styled from 'styled-components';

export const Title = styled.div`
    margin-bottom: ${props => props.isFilled ? '0.5rem' : '1.2rem'};
    font-size: ${props => props.isFilled ? '12px' : 'initial'};
    transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1);
`;

export const Value = styled.span`
    color: #000;
`

export const DropContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
	border: ${props => props.isActive ? '1px solid ' + primary : '1px dashed #e2e2e1'};
	border-radius: 12px;
	width: 100%;
	margin-bottom: 1rem;
	background-color: #fcfcfb;
	font-size: 1rem;
	padding: 1.2rem;
	line-height: 1;
	color: rgba(0, 0, 0, 0.54);
	outline: none;
`;

export const Browse = styled.span`
	color: ${primary};
	font-weight: 500;
	border-bottom: 1px solid ${primary};
	cursor: pointer;
`;
