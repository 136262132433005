import React from 'react';
import Footer from "../Footer";
import AdminHeader from "./AdminHeader";
import MinimalHeader from '../MinimalHeader';
import { AuthProvider } from "../context/AuthContext";

const AdminLayout: React.FC = ({children}) => {
    return (
        <AuthProvider>
            <MinimalHeader />
            {children}
        </AuthProvider>
    );
};

export default AdminLayout;
