import React from 'react';
import { fade, FormControl, InputBase, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';

const CustomSelectField = withStyles((theme) => ({
    input: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: '#fcfcfb',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        padding: '27px 12px 10px',
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&:focus': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
        },
    },
    focused: {},
}))(InputBase);

interface Item {
    key: string | number;
    value: string;
}

interface Props {
    items: Item[]
    label: string;
    id: string;
    value: any;
    onChange: (event) => void;
}

const CustomSelect: React.FC<Props> = ({ items, label, id, value, onChange, ...rest }) => {
    return (
        <FormControl variant="filled" fullWidth {...rest}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                labelId={id}
                value={value}
                onChange={onChange}
                input={<CustomSelectField />}
            >
                { items.map((item, i) => <MenuItem key={i} value={item.value}>{item.key}</MenuItem>) }
            </Select>
        </FormControl>
    );
};

export default CustomSelect;
