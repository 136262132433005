import React, { useCallback, useState } from 'react';
import axios from '../util/axios';
import { useDropzone } from 'react-dropzone';
import { Browse, DropContainer, Title, Value } from '../styles/Dropzone.styles';
import { AxiosError } from 'axios';

interface Props {
    onFinish: (assetUrl: string) => void;
    onError: (error: AxiosError) => void;
}

const UploadPdf: React.FC<Props> = ({ onFinish, onError }) => {
    const [objectKey, setObjectKey] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const onDrop = useCallback(async files => {
        setIsUploading(true);

        const file = files[0];

        let s3UploadUrl = '';

        try {
            const response = await axios.get(`/asset/upload?name=${file.name}`);
            s3UploadUrl = response.data.url;
        } catch (err) {
            setIsUploading(false);
            return onError(err);
        }

        try {
            await axios({
                method: 'put',
                baseURL: s3UploadUrl,
                data: file,
                headers: { 'Content-Type': 'application/pdf' }
            });

            setIsFinished(true)
            setObjectKey(file.name)
            onFinish(file.name);
        } catch (err) {
            return onError(err);
        }

        setIsUploading(false);
    }, [])

    const options = {
        onDrop,
        accept: 'application/pdf',
        maxFiles: 1
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone(options)

    const renderText = () => {
        if (isFinished) {
            return null;
        }

        if (isUploading) {
            return <span>Uploading...</span>
        }

        return <span>Drag & drop or <Browse>browse</Browse></span>;
    }

    return (
        <DropContainer {...getRootProps()} isActive={isDragActive}>
            <input {...getInputProps()} />
            <Title isFilled={!!objectKey}>PDF</Title>
            { objectKey && <Value>{objectKey}</Value> }
            {renderText()}
        </DropContainer>
    )
};

export default UploadPdf;
