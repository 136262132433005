import React from 'react';
import AddProduct from "../../components/admin/AddProduct";
import AdminLayout from "../../components/admin/AdminLayout";

const AddProductPage: React.FC = () => {
    return (
        <AdminLayout>
            <AddProduct />
        </AdminLayout>
    );
};

export default AddProductPage;
