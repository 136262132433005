import { Alert } from 'antd';
import { navigate } from 'gatsby';
import UploadPdf from '../UploadPdf';
import axios from '../../util/axios';
import styled from 'styled-components';
import UploadThumb from '../UploadThumb';
import CustomInput from '../CustomInput';
import { useForm } from 'react-hook-form';
import CustomSelect from '../CustomSelect';
import { Button } from '@material-ui/core';
import { Product, ProductInput } from '../../types/types';
import { boxShadow } from '../../util/theme';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { modularScale } from '../../util/modularScale';

interface FormData {
    title: string;
    subtitle: string;
    desc: string;
    pages: number;
    length: string;
    format: string;
    video: string;
    price: number;
    slug: string;
}

const StyledForm = styled.form`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 1800px;
	margin: 2rem auto;
    background-color: #fff;
    padding: 4rem;
    box-shadow: ${boxShadow};
`;

const Section = styled.div`
	margin: 2rem 0;
	width: 100%;
`;

const SectionTitle = styled.h2`
	font-size: ${modularScale(1)};
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const Column = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0 1rem;
	flex: 1 1 25%;
`;

const FormInput = styled(CustomInput)`
	margin-bottom: 1rem;
`;

const FormSelect = styled(CustomSelect)`
    margin-bottom: 1rem;
`;

const AddProduct: React.FC = () => {
    const [difficulty, setDifficulty] = useState('');
    const [category, setCategory] = useState('');
    const [thumb, setThumb] = useState('');
    const [pdf, setPdf] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isAuthenticated } = useContext(AuthContext);
    const { register, handleSubmit, errors } = useForm<FormData>();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated]);

    const onChangeDifficulty = (event) => {
        setDifficulty(event.target.value);
    };

    const onChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const onPdfFinish = (asset) => {
        setPdf(asset);
    }

    const onPdfError = (err) => {
        setError(err.message);
    }

    const onThumbFinish = (asset) => {
        setThumb(asset);
    }

    const onThumbError = (err) => {
        setError(err.message);
    }

    const onSubmit = (values) => {
        setIsSubmitting(true);
        setError('');

        const product: ProductInput = {
            pdf,
            thumb,
            title: values.title,
            subtitle: values.subtitle,
            desc: values.desc,
            category,
            difficulty,
            pages: parseInt(values.pages, 10),
            length: values.length,
            format: values.format,
            price: parseInt(values.price, 10),
        }

        if (values.video) {
            product.video = values.video;
        }

        if (values.slug) {
            product.slug = values.slug;
        }

        if (!validate(product)) {
            setIsSubmitting(false);
            return;
        };

        addProduct(product);
    }

    const validate = (product: ProductInput): boolean => {
        const required = [
            'pdf', 'thumb', 'title', 'subtitle', 'desc', 'category',
            'difficulty', 'pages', 'length', 'format', 'price'
        ];

        const missing: string[] = [];

        required.forEach(field => {
            if (!product[field]) missing.push(field);
        })

        if (missing.length > 0) {
            setError('The following fields are required: ' + missing.join(', '));
            return false;
        }

        return true;
    }

    const addProduct = async (product: ProductInput) => {
        try {
            const response = await axios.post('/products', product);
            setSuccess('Product added to database.');
        } catch (err) {
            setError(err.message);
        }

        setIsSubmitting(false);
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>

            { success && <Alert type="success" message={success} /> }
            { error && <Alert message={error} /> }
            <Row>
                <Column>
                    <Section>
                        <SectionTitle>Assets</SectionTitle>
                        <UploadPdf onFinish={onPdfFinish} onError={onPdfError}/>
                        <UploadThumb onFinish={onThumbFinish} onError={onThumbError}/>
                    </Section>
                </Column>
                <Column>
                    <Section>
                        <SectionTitle>Product Info</SectionTitle>
                        <FormInput label="Title" name='title' inputRef={register({ required: true })}/>
                        <FormInput label="Subtitle" name='subtitle' inputRef={register({ required: true })}/>
                        <FormInput multiline rows={10} label="Description" name='desc' inputRef={register({ required: true })}/>
                    </Section>
                </Column>
                <Column>
                    <Section>
                        <SectionTitle>Metadata</SectionTitle>
                        <FormSelect
                            items={[
                                { key: 'Piano Solo', value: 'Piano Solo' },
                                { key: 'Piano Duet', value: 'Piano Duet' }
                            ]}
                            label="Category"
                            id="category"
                            value={category}
                            onChange={onChangeCategory}
                        />

                        <FormSelect
                            items={[
                                { key: 'Beginner', value: 'Beginner' },
                                { key: 'Intermediate', value: 'Intermediate' },
                                { key: 'Advanced', value: 'Advanced' }
                            ]}
                            label="Difficulty"
                            id="difficulty"
                            value={difficulty}
                            onChange={onChangeDifficulty}
                        />

                        <FormInput label="Pages" name='pages' type='number' inputRef={register({ required: true })}/>
                        <FormInput label="Length" name='length' inputRef={register({ required: true })}/>
                        <FormInput label="Format" name='format' inputRef={register({ required: true })}/>
                        <FormInput label="Video URL" name='video' inputRef={register}/>
                        <FormInput label="Slug" name='slug' inputRef={register}/>
                    </Section>
                </Column>
                <Column>
                    <Section>
                        <SectionTitle>Shop Info</SectionTitle>
                        <FormInput label="Price (cents)" name='price' type="number" inputRef={register({ required: true })}/>
                    </Section>
                </Column>
            </Row>
            <Button type="submit" variant="outlined" color="primary">Submit</Button>
        </StyledForm>
    );
};

export default AddProduct;
